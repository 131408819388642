@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
width: 100%;
height: 100%;
overflow: hidden;
/*smooth scrool*/
/* overflow-y: scroll; */
/* scroll-behavior: smooth */
/* background: url(./images/glass/IMG_7197.JPG)no-repeat center center/cover; */

}
.big-title {
  font-size: 2rem;
  padding: 20px;
  letter-spacing: 2.8rem;
  background: none;
  position: absolute;
  margin-right: -20vw;
  top: 44%;
  color:white;
  background: #26303D;
  width: 77.5vw;

}
.navbar {
  margin-top: 250px;
  position: fixed;

  z-index: 1;
  display: flex;
left: 0;

 width: 300px;
  height: 200px;
 margin-left: 15px;
  /* background: rgb(252, 250, 250); */


 
}
nav h1 {
  font-size: 1.5rem;

font-weight: normal;
letter-spacing: 3px;
}

.navbar-mobile {
  
  position: fixed;
  top: 0;

  z-index: 1;
  display: flex;
  background:rgba(0,0,0,0.6);
  

 width: 100vw;
  height: 50px;
  /* background: rgb(252, 250, 250); */


 
}
.navbar-mobile-work{
  
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 1;
  display: flex;
  background: #26303D;
  z-index:8;
justify-content:center;
 width: 140px ;
  height: 90vw;
  /* background: rgb(252, 250, 250); */
  
 
}

html {
  
  -ms-scroll-snap-type: y proximity;
  
      scroll-snap-type: y proximity;
  transition: all 1s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.navbar ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100vw;
  justify-content: center;
  
}
.navbar-mobile ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100vw;
  justify-content: center;
  align-items: space-around;


}
.navbar-mobile-work ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100vw;
  justify-content: center;
  
  position: absolute;
  top: 10%;

}

.navbar ul li {
  margin: 0 1rem;
  padding: 1rem;
  color: white;
  letter-spacing: 3px;
  width: 250px;
  
}
.navbar-mobile ul li {
  margin: 0;
margin: 5px;
  color: white;
  letter-spacing: 3px;

  
}
.navbar-mobile-work ul li {
  margin: 0;
margin: 5px;
  color: white;
  letter-spacing: 3px;
  margin-top: 10px;
  padding: 5px;
  font-size: .8rem;

  
}

.navbar ul li > .active {
  border-left: 4px solid rgb(224, 248, 156);

  padding-left: 10px;
  text-decoration: none;
 font-size: 1.2rem;
  color: white
}
* {
  margin:0;
  padding:0
}
.navbar-mobile ul li > .active {
  border-bottom: 2px solid rgb(224, 248, 156);

  padding-bottom: 5px;
  text-decoration: none;
 font-size: 1.2rem;
  color: white
}
* {
  margin:0;
  padding:0
}


 ul li:hover {
  color: #87ceeb;
  cursor: pointer;
}

body {
  font-family: 'Lato', sans-serif;
  background: #26303D;
  color: #fff;
  line-height: 1.6;
  height: 100vh;
  
}



section#home {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center  ;
text-align:center;
width: 100%;
height: 100vh;
color: white;


}
.home-gallery {
  
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(12, 1fr);

 width: 80vw;
 height: 100vh;
 margin-right: -20vw;
background:white;

  border-radius: 0rem;
 

  
}
.home-gallery-mobile {
  
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(12, 1fr);

 width: 80vw;
 height: 50vh;

   border-radius: 0rem;
 

  
}
.home1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.home-image-1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 13;
  transition: all .7s ease-in-out;
 
}
.home-image-1:hover {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 13;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  transform: scale(.95);
}
.home-image-2 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 13;
  transition: all .7s ease-in-out;
}
.home-image-2:hover {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 13;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: scale(.95);
}
.home-image-3 {
  grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 13;
  transition: all .7s ease-in-out;
 
}
.home-image-3:hover {
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 13;
  z-index: 4;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: scale(.95);
}
.home-image-4 {
  grid-column-start: 13;
  grid-column-end: 17;
  grid-row-start: 1;
  grid-row-end: 13;
  transition: all .7s ease-in-out;
}
.home-image-4:hover {
  grid-column-start: 11;
  grid-column-end: 18;
  grid-row-start: 1;
  grid-row-end: 13;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: scale(.95);
}

section {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align:center;
width: 100%;
height: 100vh;


 

}

section#peapods {
  

}
footer {
  
  height: 180px;
  background: #50637D;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 20px 0px 20px;
 
}
.footer-mobile  {
  height: 10%;
  background: #50637D;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-between;
  padding: 10px;
 width: 100vw;
 font-size: .8rem;
 text-align: left;
}
.footer-link {
  margin: 5px;
}

footer a {
  text-decoration: none;
  border-bottom: 1px solid rgba(224, 248, 156);
  padding-bottom: 3px;
  color: white;
}
footer a:hover {
  color: skyblue!important;
}
 footer a:visited {
  text-decoration: none;
  color: white;
}
footer span {
  display: flex;
  flex-direction: column;
  align-items:center
}
.award-container {
 border-left: 4px solid rgba(224, 248, 156);
  padding: 5px;
  width: 60%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.award-container  > p{
letter-spacing: 2px;
margin-bottom: 5px;
}
 .awards {
   display: flex;
   flex-direction: row;

 }
 .award {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   text-align: center;
   width: 200px;
   margin: 20px;
   
 }

.image-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.catalog-image1 {
  width: 400px;
  flex-grow: 2;
  margin: 10px
}
.catalog-image {
  width: 400px;
  
}

section#home h1 {
  font-size: 4rem;
  margin: 0 1rem;
  padding: 1rem;
  font-weight: normal;
  letter-spacing: 3px;
  margin-right:  -156px;
  background:#26303D;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}


.glass-container {
  width: 80vw;
  height: 100vh;
  margin-right: -20vw;
  background: white;
  color: black;
  display: flex; 
  flex-direction: row;
  align-items:flex-start;
 
}
.glass-container-mobile {
  width: 100vw;
  height: 100vh;
 
  background: white;
  color: black;
  display: flex; 
  flex-direction: column;
  align-items:center;
 padding-bottom: 50px;
}
.form-container {
width: 80vw;
height: 100vh;
margin-right: -20vw;
background: rgb(240, 240, 245);
/* border-top: 4px solid #26303D; */

text-align: center;
}
.form-container-mid {
width: 100vw;
height: 100vh;

background: rgb(240, 240, 245);
/* border-top: 4px solid #26303D; */

text-align: center;
}
.form-container-mid h2{
margin-top: 70px;
letter-spacing: .5rem;
text-align: center;
}
.form-container-mobile {
width: 100vw;
height: 100vh;
padding-top: 30%;
background: rgb(240, 240, 245);

}
.form-container-mobile h2 {
letter-spacing: 5px;
}

.form-container-mobile form {
  display: flex;
flex-direction: column;
justify-content:center;
align-items:center;

}

.form-container-mobile input {
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  font-size: 1em;
  width: 200px;


  height: 50px;
  padding: 0px 15px 0px 15px;
  
  background: transparent;
  outline: none;
  color: #726659;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out
}
.form-container-mobile textarea {
  width: 250px;
 

 
  height: 110px;
  max-height: 110px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
  outline: none;
  
  color: #726659;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.form-container-mobile #submit-mobile {
  width: 250px;
 
  
}
.form-container-mobile #submit-mobile:active {
  width: 250px;
margin-left: 0;
}

.glass-container h2{

letter-spacing: 1rem;
font-weight: bolder;
font-size: 2rem;

}

.glass-container h3{
border-bottom: 4px solid  rgba(224, 248, 156);
letter-spacing:.5rem;
padding-bottom: 5px;
font-weight: bolder;


}
.glass-container-mobile h2{
border-bottom: 4px solid  rgba(224, 248, 156);
letter-spacing:.5rem;
padding-bottom: 5px;

font-weight: bolder;
margin-top: 5vw;
margin-bottom:2vw
}

.glass-container article {
  margin:  0vw 0vw 0vw 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align:left;
width: 50%;
height: 100%;
line-height: 2rem;

}
.glass-container article p {
 
padding-right: 50px;
}
.glass-container-mobile article {
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
text-overflow: hidden;
height: 100%;
}
.glass-container-mobile article p {


}

.about-picture-container {
  width: 50%;
  height: 100%;
  display:flex;
 
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-picture-container-mobile {
  width: 100%;
  height: 80%;
  display:flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-picture-container img {
width: 75%;
height: 80%;
object-fit: contain;

}
.about-picture-container-mobile img {
width: 75%;
height: 80%;
object-fit: contain;
margin-top: -2vw;

}
.about-picture-container span {
width: 75%;
object-fit: contain;
margin-top: 10px;

font-size: .8rem;
}
.about-picture-container-mobile span {

padding: 10px;

font-size: .6rem;
margin-left: 5px;
margin-right: 5px;
}

.lantern {
  width: 700px;
  margin: 20px;
  }

  .logo {
    width: 60px;
  
  }

  .glass {
    background: white;
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.7), rgba(255,255,255,0.3));
   border-radius: 1rem;
   padding: 10px;
   
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 50px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  .material-icons:hover {
    color: skyblue;
    font-size: 70px;
  }

  
.glass-h1{
  color: #50637D;
 border-radius: 1rem;
 padding: 10px;
}
@media screen and (max-width: 375px) {
  .navbar ul li {
    margin: 0 1rem;
    padding: 1rem 0rem
  }
  /* section#home::after {
    content: "";
    background: url(./images/glass/Green\ Pea\ Pod.JPG) no-repeat right center/cover;;
    opacity:1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  } */
  section#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:center;
    width: 100%;
    height: 100vh;
    
    }
    section#home h1 {
      margin-top: 100px;
      color: #50637D;
      }
    }
   .link {
     color: white;
     text-decoration: none;
   
   }
   .button-link {
    border: 1px solid white;
    padding: 10px;
    width: 315px;
    color: white;
    text-decoration: none;
    font-weight: bold;
   }
   .button-link:hover {
    border: 1px solid white;
    /* border-top: 1px solid white;
    border-bottom: 3px solid white; */
    padding: 15px;
    transition: padding 0.3s ease-in-out;
  -webkit-transition: padding 0.3s ease-in-out;
  -moz-transition: padding 0.3s ease-in-out;
  -ms-transition: padding 0.3s ease-in-out;
    /* width: 350px; */
     font-weight: bold;
     background: url(/static/media/IMG_7197.13117203.JPG)no-repeat center center/cover; 
   }
   .link:hover {
    color: skyblue;
  
   }
  
    .arrow {
      width: 10px;
      height: 10px;
    }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aca49c;
  font-size: 1em;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
  color: #bbb5af;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aca49c;
  font-size: 1em;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #aca49c;
  font-size: 1em;
}

input::placeholder, textarea::placeholder {
  color: #aca49c;
  font-size: 1em;
}

input:focus::-webkit-input-placeholder {
  color: #bbb5af;
}

input:focus:-ms-input-placeholder {
  color: #bbb5af;
}

input:focus::placeholder, textarea::focus:placeholder {
  color: #bbb5af;
}

input::-ms-placeholder, textarea::-ms-placeholder {
  color: #aca49c;
  font-size: 1em;
}

input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
  color: #bbb5af;
}

.form-container-mobile input:focus, .form-container-mobile textarea:focus{
  color: white;

  border: 2px solid #273241;
 

}
input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
  color: #e2dedb;
  font-size: 1em;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
  color: #cbc6c1;
}

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
  color:white;
  font-size: 1em;
}

input:hover:-ms-input-placeholder, textarea:hover:-ms-input-placeholder {
  color:white;
  font-size: 1em;
}

input:hover::placeholder, textarea:hover::placeholder {
  color:white;
  font-size: 1em;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
  color: white;

}

input:hover:focus:-ms-input-placeholder, textarea:hover:focus:-ms-input-placeholder {
  color: white;

}

input:hover:focus::placeholder, textarea:hover:focus::placeholder {
  color: white;

}

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
  color: #e2dedb;
  font-size: 1em;
}

input:hover:-ms-input-placeholder, textarea:hover:-ms-input-placeholder {
  color: #e2dedb;
  font-size: 1em;
}

input:hover::placeholder, textarea:hover::placeholder {
  color: #e2dedb;
  font-size: 1em;
}

input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
  color: white;
}



header {
  position: relative;
  margin: 100px 0 25px 0;
  font-size: 2.3em;
  text-align: center;
  letter-spacing: 7px;
}

#form {
  position: relative;
  width: 500px;
  margin: 50px auto 100px auto;

}

input {
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  font-size: 0.875em;
  width: 100%;
  height: 50px;
  padding: 0px 15px 0px 15px;
  
  background: transparent;
  outline: none;
  color: #726659;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

input:hover {
  background:#26303D;
  color: white;
  
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 110px;
  max-height: 110px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: transparent;
  outline: none;
  
  color: #726659;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

textarea:hover {
  background: #26303D;
  color: #e2dedb;
}

#submit {
  font-family: 'Lato', sans-serif;
  margin-top: 10px;

  font-size: 0.875em;
  width: 50%;
  max-width: 60%;
  height: 50px;
  padding: 0px 15px 0px 15px;
  margin-left: 5%;
  background: transparent;
  outline: none;
  color: #726659;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

#submit:hover {
  background:#26303D;
  color: white;
  
}

#submit:focus {
  font-family: 'Lato', sans-serif;

  font-size: 0.875em;

 


  
  background: #26303D;
  outline: none;
  color: white;
  
  border: solid 0.5px rgb(134, 137, 146);
  border-radius: 1rem;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.each-slide > div img {
 
  width: 500px;
  height: 500px;
  object-fit: contain;

}


.each-slide div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-container {
background: white;

width: 50%;
margin-right: -200px
}

.gallery {
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(32, 5vw);
  grid-gap: 15px;
  width: 76vw;
  height: 100vh;
  background: white;
margin-left: 290px;

  border-radius: 0rem;
  border-left: 4px solid #50637D;
  overflow-y: auto;
  
}
.gallery-mobile {
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(32, 5vw);
  grid-gap: 15px;
  width: 100vw;
  height: 100vh;
  background: white;
color: black;

  border-radius: 0rem;
  border-left: 4px solid #50637D;
  overflow-y: auto;
  
}
.gallery-mobile figure {
  font-size: .5rem 
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}
.gallery__img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gallery__item-1 {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 2;
  grid-row-end: 9;
}
.gallery__item-1-mobile {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 4;
  grid-row-end: 11;
}
.gallery__item-1-lantern {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 9;
}
.gallery__item-1-lantern-mobile {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 6;
  grid-row-end: 12;
}
.gallery__item-2-lantern {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 9;
  grid-row-end: 19;
  
}
.gallery__item-2-lantern-mobile {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 13;
  grid-row-end: 20;
  
}
.gallery__item-1-pea {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 10;
}
.gallery__item-1-pea-mobile {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 6;
  grid-row-end: 13;
}
.gallery__item-2 {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 17;
  grid-row-end: 23;
}
.gallery__item-2-mobile {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 22;
  grid-row-end: 28;
}
.gallery__item-2-pea {
  grid-column-start:2;
  grid-column-end: 14;
  grid-row-start: 11;
  grid-row-end: 18;
}
.gallery__item-2-pea-mobile {
  grid-column-start:2;
  grid-column-end: 14;
  grid-row-start: 14;
  grid-row-end: 21;
}
.gallery__item-2-acorns{
  grid-column-start:2;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 9;
  
}
.gallery__item-2-acorns-mobile{
  grid-column-start:2;
  grid-column-end: 14;
  grid-row-start: 4;
  grid-row-end: 13;
  
}
.gallery__item-3-acorns{
  grid-column-start:1;
  grid-column-end: 10;
  grid-row-start:10;
  grid-row-end: 14;
}
.gallery__item-3-acorns-mobile{
  grid-column-start:1;
  grid-column-end: 9;
  grid-row-start:13;
  grid-row-end: 17;
}
.gallery__item-4-acorns{
  grid-column-start: 7;
  grid-column-end: 15;
  grid-row-start: 10;
  grid-row-end: 14;
}
.gallery__item-4-acorns-mobile{
  grid-column-start: 7;
  grid-column-end: 15;
  grid-row-start: 13;
  grid-row-end: 17;
}
.gallery__item-5 {
  grid-column-start: 8;
  grid-column-end:15;
  grid-row-start: 17;
  grid-row-end: 24;
}
.gallery__item-5-acorns{
  grid-column-start:1;
  grid-column-end: 10                 ;
  grid-row-start: 15;
  grid-row-end: 19;
}
.gallery__item-5-acorns-mobile{
  grid-column-start:1;
  grid-column-end: 9;                 ;
  grid-row-start: 18;
  grid-row-end: 22;
}
.gallery__item-6-acorns{
  grid-column-start:7;
  grid-column-end: 15;
  grid-row-start: 15;
  grid-row-end: 19;
}
.gallery__item-6-acorns-mobile{
  grid-column-start:7;
  grid-column-end: 15;
  grid-row-start: 18;
  grid-row-end: 22;
}


.gallery__item-3 {
  grid-column-start: 1;
  grid-column-end:8;
  grid-row-start: 17;
  grid-row-end: 24;
}
.gallery__item-3-mobile {
  grid-column-start: 4;
  grid-column-end:12;
  grid-row-start: 32;
  grid-row-end: 40;
  
}
.gallery__item-4 {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 10;
  grid-row-end:16;

}
.gallery__item-4-mobile{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 12;
  grid-row-end:19;

}

.gallery__item-5-mobile {
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 20;
  grid-row-end: 31;
 
}
.gallery__item-1-urchin {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 10;
}
.gallery__item-1-urchin-mobile {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 5;
  grid-row-end: 11;
}
.gallery__item-2-urchin {
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 10;
  
}
.gallery__item-2-urchin-mobile {
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 5;
  grid-row-end: 11;
  
}
.gallery__item-3-urchin {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 11;
  grid-row-end: 18;
  
}
.gallery__item-3-urchin-mobile {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 12;
  grid-row-end: 18;
  
}
.gallery__item-4-urchin {
  grid-column-start: 7;
  grid-column-end: 15;
  grid-row-start: 11;
  grid-row-end: 18;
  
}
.gallery__item-4-urchin-mobile {
  grid-column-start: 7;
  grid-column-end: 15;
  grid-row-start: 12;
  grid-row-end: 18;
  
}
.gallery__item-5-urchin {
  grid-column-start: 1;
  grid-column-end:8;
  grid-row-start: 19;
  grid-row-end: 25;
  
}
.gallery__item-5-urchin-mobile {
  grid-column-start: 2;
  grid-column-end:14;
  grid-row-start: 19;
  grid-row-end: 26;
  
}
.gallery__item-6-urchin {
  grid-column-start: 8;
  grid-column-end: 15;
  grid-row-start: 19;
  grid-row-end: 25;
  
}
.gallery__item-6-urchin-mobile {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 27;
  grid-row-end: 34;
  margin-right:5px
  
}
#down-arrow {
color:#26303D
}
.gallery__item-acorn-arrow {
  grid-column-start: 1;
  grid-column-end:2;
  grid-row-start: 9;
  grid-row-end: 10;
  margin-top: 10px;
  
}

.colorspread {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 200px;
  
}
.colorspread div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}
.colorspread span {
  height: 25px;
  width: 35px;
  margin:10px;

  display: inline-block;

  background-color: #750fa8
}
#blue-picker {
height: 25px;
width: 35px;

margin:10px;
display: inline-block;

background-color:#0686c2
}
#amber {
height: 25px;
width: 35px;

margin:10px;
display: inline-block;

background-color:#e8b02e
}
#opacity {
  object-fit: stretch
}
.contain{
  object-fit:contain;
  width: 100%;
  HEIGHT: 100%;
 
}
.contain1{
  object-fit: contain;
  width: 100%;
  HEIGHT: 100%;
  border: 5px solid white;
}
.ocotillo-desc {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 3;
  color: black;
}
.ocotillo-desc-mobile {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 3;
  color: black;
  
}
section#bowls {
  
  background:#26303D
  
  
 
 }
#arrow {
  width: 50px;
  background: none;
  position: absolute ;
  top: 5%;
  left: 10%;
  padding: 5px;
}
#arrow1{
  width: 50px;
  background: none;

  padding: 5px;
}
section#contact {
  color: black;
 
}
.form-container h2 {
  color: black;
  margin-right: -30px;
  letter-spacing: .5rem;
  margin-top: 10%;
}
.form-container p {
  margin-right: -20px;
}
 #arrow:hover{
border: 2px solid rgba(224, 248, 156);
border-radius: 3rem;

cursor: pointer
 }
 #arrow1:hover{
border: 2px solid rgba(224, 248, 156);
border-radius: 3rem;

cursor: pointer
 }
 .gallery p {
  padding: 1rem;
  color: black;
 
 }
 .gallery-mobile p {
 
  color: black;
  
 }
 .gallery h2 {
  letter-spacing: 3px;
  border-bottom: 4px solid rgba(224, 248, 156);
  padding-bottom: 10px;
 }
 .gallery-mobile h2 {
  letter-spacing: 3px;
  border-bottom: 4px solid rgba(224, 248, 156);
  padding-bottom: 5px;
 }


 /* #273241 */
 /* #50637D; */




/* 
 MOBILE VERSION  */
 /* @media screen and (max-width: 375px) {
  .container {
width: 375px;
height: 667px;
/*smooth scrool*/
/* overflow-y: scroll; */
/* scroll-behavior: smooth */
/* background: url(./images/glass/IMG_7197.JPG)no-repeat center center/cover; */


.mobile-title {
letter-spacing: 1rem;
margin: 5vw
}

.work-button-mobile {
  background: rgba(0,0,0,0.5);
  color: white;
  padding: 10px;
  width: 100px;
  border-radius: 2rem;
border: none;
  letter-spacing: .2rem;
  text-align: center;
  margin: 5vw;
}

.work-button-mobile:active {
  border: none;
  outline: none;
  margin: 5vw;
}

.modal {

  display: flex; /* Hidden by default */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  color: white;
}

.modal-img {
  margin: auto;
 margin-top: 30%;
  width: 70%;
 
}

.bi-zoom-in{
color: black;
}

.read-more-button {
  background:#50637D;
  color:white;
  padding: 10px;
  width: 110px;
  border-radius: 2rem;
border: none;
  margin: 5px;
  text-align: center
}
.read-less-button {
  background:#50637D;
  color:white;
  padding: 5px;

  border-radius:50%;
border: none;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
}
.read-less-button:hover {
  outline: none;
background: #26303D
}
.read-more-button:hover {
outline: none;
background: #26303D
}
#read-more-arrow {
margin-bottom: -3px;
}

.read-more-container-mobile {
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
  height: 100%;

  font-size: .9rem;
}

#close-x {


  position: absolute;
  top: 0;
  left: 0;
}

#home-icon {
  position: absolute;
  top: 0;
  left: 75%;
  margin: 5px;
}
